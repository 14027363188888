:root {
  --principal_azul_oscuro: #192D6F;
  --principal_azul_agua_marina: #66EAFE;
  --principal_purpura: #8759FC;
  --scroll: #8759FC;

  --secundario_lila: rgba(135, 89, 252, 0.5);
  --secundario_gris_frio: rgba(25, 45, 111, 0.5);
  --secundario_azul_agua_marina: rgba(102, 234, 254, 0.5);
  --secundario_purpura: #442D7E;
  --secundario_purpura_oscuro: #0A112A;
  --secundario_cyan_oscuro: #4DB0BF;
  --secundario_gris: rgba(25, 45, 111);

  --color-option-menu: #666666;
}
@media (prefers-color-scheme: dark) { 
  body{
    --ion-background-color: white !important;
    --ion-text-color: black !important;
  }
 }


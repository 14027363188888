/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
//ts-ignore
@import '~@ng-select/ng-select/themes/material.theme.css';
@import "./variable";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Montserrat;
}
.ion-page{
  
  overflow-x: hidden !important;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: var(--scroll);
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
}
.ng-dropdown-panel-items {
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: var(--scroll);
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
}
.ng-placeholder {
  max-width: 116px;
  max-height: 15px;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #666666 !important;
}
app-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ng-select.custom .ng-select-container  {
  min-height: 32px;
  border-radius: 25px;
  border: 3px solid #545454;
}
.ng-select.custom-register .ng-select-container  {
  min-height: 35px;
  border-radius: 25px;
  border: 1px solid #545454;
  padding: 0 10px;
}
.ng-select.custom-register  {
  padding: 0 !important;
}
.ng-select.custom .ng-select-container .ng-placeholder, .ng-select.custom-register .ng-select-container .ng-placeholder{
  top: 5px;
  left: 10px;
  color: #6c6d6e !important;
  font-weight: 400 !important;
}
.ng-select.custom-register .ng-select-container .ng-placeholder{
  display: none;
}
.ng-select.custom-register .ng-dropdown-panel.ng-select-bottom{
  top: auto !important;
}
.ng-select.custom .ng-arrow-wrapper .ng-arrow, .ng-select.custom-register .ng-arrow-wrapper .ng-arrow{
  color: rgba(0,0,0,0.54) !important;
}
.ng-select.custom .ng-select-container::after, .ng-select.custom-register .ng-select-container::after{
  border-bottom: none !important;
}
.ng-select.custom  .ng-select-container .ng-value-container, .ng-select.custom-register  .ng-select-container .ng-value-container{
  padding: 0px 0px 0 10px !important;
}
ion-slides, ion-content {
  height: 800px;
  width: 100%;
  @media screen and (max-width: 576px) {
    width: 100%;
    height: 375px;
  }
}
// ion-slide {
//   height: 100% !important;
//   width: 100% !important;
// }

.error {
  color: red !important;
  border-color: red !important;
  .ng-select-container {
    border-color: red !important;
  }
}

.ng-select {
  .ng-select-container {
    justify-content: center;
    align-items: center;
  }
  .ng-value-container {
    border: none !important;
  }
}

input { background: white;}

.codeModal::part(content){
  --max-width: 480px !important;
  --max-height: 550px !important;
  --border-radius: 40px;
}

.signInModal::part(content){
  --max-width: 480px !important;
  --max-height: 500px !important;
  --border-radius: 40px;
}

.applicationStatusModal::part(content){
  --max-width: 410px !important;
  --max-height: 535px !important;
  --border-radius: 40px;
}

ion-accordion.accordion-expanded ion-item[slot="header"] {
  --color: white;
  --background: #131B2F !important
}

ion-item {
    background: white;
}

ion-app{
  background: white;
  color: black;
}

.row-actualizar-datos .ng-select, .row-payment .ng-select{
  padding-bottom: 0px;
}

.row-actualizar-datos .ng-select.perfil .ng-select-container, 
.row-payment .ng-select .ng-select-container,
.container-select-type-solitud .ng-select.type-solicitud-client .ng-select-container{
  border: 1px solid #545454;
  border-radius: 65px;
  height: 37px;
  min-height: 37px;
  padding: 0px 10px;
}

app-faq ion-accordion-group{
  ion-accordion {
      .ion-accordion-toggle-icon {
          font-size: 25px;
          color: #929292;
          @media screen and (max-width: 576px) {
            font-size: 15px;
          }
      }
  }
  ion-accordion.accordion-expanded{
    .ion-accordion-toggle-icon {
        font-size: 25px;
        color: #ffffff;
        @media screen and (max-width: 576px) {
          font-size: 15px;
        }
    }
}
 
}

app-profile  ion-accordion-group{
  ion-accordion {
      .ion-accordion-toggle-icon {
          font-size: 25px;
          color: #49358a;
          @media screen and (max-width: 576px) {
            font-size: 15px;
          }
      }
  }
}
